<template>
  <ais-instant-search :search-client="searchClient" :index-name="searchIndex">
	<ais-configure :query="searchString" :attributesToSnippet="['content:50']"/>
	<ais-search-box submit-title="Search" :placeholder="placeholder">
		<div slot="reset-icon"></div>
	</ais-search-box>
	<ais-hits>
		<ul slot-scope="{ items }" class="skn-search-results">
			<li v-for="item in items" :key="item.objectID" class="skn-search-result-item">
				
				<div v-if="showImage" class="search-result-image" >
					<div class="image-shim"></div>
					<img :src="selectImageSize(item.image)">
				</div>
				<h2 v-if="hasProperty(item.title)" :class="titleStyle">
					<ais-highlight  attribute="title" :hit="item" :class-names="{'ais-Highlight': 'search-result-element search-result-title', 'ais-Highlight-highlighted': 'search-term-highlight'}"/>
				</h2>
				<h3 v-if="hasProperty(item.sub_title)" :class="subtitleStyle">
					<ais-highlight attribute="sub_title" :hit="item" :class-names="{'ais-Highlight': 'search-result-element search-result-subtitle', 'ais-Highlight-highlighted': 'search-term-highlight'}"/>
				</h3>
				<h3 v-if="hasProperty(item.brief)" :class="subtitleStyle">
					<ais-highlight attribute="brief" :hit="item" :class-names="{'ais-Highlight': 'search-result-element search-result-subtitle', 'ais-Highlight-highlighted': 'search-term-highlight'}"/>
				</h3>
				<p v-if="hasProperty(item.description)" :class="descriptionStyle">
					<ais-highlight attribute="description" :hit="item" :class-names="{'ais-Highlight': 'search-result-element search-result-description', 'ais-Highlight-highlighted': 'search-term-highlight'}"/>
				</p>
				<p v-if="hasProperty(item.content)" :class="textStyle">
					<ais-snippet v-if="hasProperty(item.content)" attribute="content" :hit="item" :class-names="{'ais-Highlight': 'search-result-element search-result-description', 'ais-Highlight-highlighted': 'search-term-highlight'}"/>
				</p>
				<a class="item-link" :href="basePath + item.url"></a>
			</li>
		</ul>
	</ais-hits>
  </ais-instant-search>
</template>

<script>

import Vue from 'vue';
import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);
import algoliasearch from 'algoliasearch/lite';

export default {
	components: {
		
	},
	props:{
		placeholder:{type:String, default:'Search...'},
		searchIndex: String,
		initialSearchString: String,
		basePath:{type: String,	default: ''},
		showImage:{type: Boolean},
		titleStyle:{type:String, default:''},
		subtitleStyle:{type:String, default:''},
		descriptionStyle:{type:String, default:''},
		textStyle:{type:String, default:''},
		imageSize:{type: [Number, String], default: 1366},
		availableImageSizes:{type: String, default: ''}
	},
	data() {
		return {
			searchString: ''
		};
	},
	mounted(){
		this.searchString = this.initialSearchString;
	},
	computed: {
		algoliaClient: function(){
			return algoliasearch('LNRHW5LMRS','da41df95c570bdf0918f200f8e846164');
		},
		searchClient: function(){
			let t = this;
			let searchObject = {
				search(requests) {
					if (requests.every(({ params }) => params.query.length < 3)) {
						return Promise.resolve({
							results: requests.map(() => ({
								hits: [],
								nbHits: 0,
								nbPages: 0,
							})),
						});
					}
					return t.algoliaClient.search(requests);
				}
			}
			return searchObject;
		},
		imageSizeArray: function(){
			var a = JSON.parse(this.availableImageSizes);
			return a;
		},
		/* Account for pixel density */
		adjustedImageSize: function(){
			var newSize = this.imageSize;
			if(window.devicePixelRatio > 1){
				newSize = newSize * window.devicePixelRatio;

				for (var i = 0; i < this.imageSizeArray.length; i++) {
					if(newSize <= parseInt(this.imageSizeArray[i])){
						newSize = parseInt(this.imageSizeArray[i]);
						break;
					}
				}
			}
			return newSize;
		}
	},
	methods:{
		//rewrites the image to an appropriate resize
		selectImageSize: function(image){

			var segs = image.split('/');
			var index = segs.indexOf('images');

			if(index > -1)
			{
				var currentSize = segs[index + 1];
				return image.replace('/'+currentSize+'/', '/'+this.adjustedImageSize+'/');
			}
			return image;
		},
		hasProperty: function(prop){
			if(prop !== undefined && prop.length > 0){
				return true;
			}
			return false;
		}
	}
};
</script>
